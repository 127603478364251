const DownloadIcon = ({ color = '#0081BC', stroke = '1.25' }: { color?: string; stroke?: string }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_990_3978)">
      <path d="M3.33334 16.6665H16.6667" stroke={color} strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 11.6668V3.3335" stroke={color} strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 11.6668L13.3333 8.3335" stroke={color} strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.99999 11.6668L6.66666 8.3335" stroke={color} strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_990_3978">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export { DownloadIcon }
