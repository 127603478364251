const InvoiceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <g clipPath="url(#clip0_1030_5465)">
        <path
          d="M11.6667 3V6.33333C11.6667 6.55435 11.7545 6.76631 11.9107 6.92259C12.067 7.07887 12.279 7.16667 12.5 7.16667H15.8333"
          stroke="black"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8333 10.5V16.3333C15.8727 16.6478 15.8104 16.9667 15.6554 17.2431C15.5004 17.5196 15.2609 17.7392 14.972 17.8696C14.6832 18.0001 14.3601 18.0346 14.0502 17.968C13.7403 17.9015 13.4599 17.7375 13.25 17.5C13.1214 17.3355 12.957 17.2024 12.7693 17.1109C12.5816 17.0193 12.3755 16.9718 12.1667 16.9718C11.9578 16.9718 11.7517 17.0193 11.564 17.1109C11.3763 17.2024 11.2119 17.3355 11.0833 17.5C10.9547 17.6645 10.7903 17.7976 10.6026 17.8891C10.4149 17.9807 10.2088 18.0282 9.99999 18.0282C9.79116 18.0282 9.58507 17.9807 9.39737 17.8891C9.20966 17.7976 9.04527 17.6645 8.91666 17.5C8.78806 17.3355 8.62366 17.2024 8.43596 17.1109C8.24825 17.0193 8.04216 16.9718 7.83333 16.9718C7.62449 16.9718 7.41841 17.0193 7.2307 17.1109C7.04299 17.2024 6.8786 17.3355 6.74999 17.5C6.54011 17.7375 6.25967 17.9015 5.94977 17.968C5.63988 18.0346 5.31683 18.0001 5.02797 17.8696C4.73911 17.7392 4.49962 17.5196 4.34463 17.2431C4.18963 16.9667 4.12726 16.6478 4.16666 16.3333V4.66667C4.16666 4.22464 4.34226 3.80072 4.65482 3.48816C4.96738 3.17559 5.3913 3 5.83333 3H11.6667L15.8333 7.16667V10.7083"
          stroke="black"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_5465">
          <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export { InvoiceIcon }
