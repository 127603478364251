const SendIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_990_4035)">
      <path d="M8.33334 11.6667L17.5 2.5" stroke="#0071BC" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M17.5 2.5L12.0833 17.5C12.0468 17.5798 11.9881 17.6474 11.9142 17.6948C11.8403 17.7422 11.7544 17.7674 11.6667 17.7674C11.5789 17.7674 11.493 17.7422 11.4191 17.6948C11.3453 17.6474 11.2866 17.5798 11.25 17.5L8.33333 11.6667L2.5 8.75C2.42021 8.71344 2.3526 8.65474 2.3052 8.58088C2.2578 8.50701 2.2326 8.4211 2.2326 8.33333C2.2326 8.24557 2.2578 8.15965 2.3052 8.08579C2.3526 8.01193 2.42021 7.95323 2.5 7.91667L17.5 2.5Z"
        stroke="#0071BC"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_990_4035">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export { SendIcon }
