import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ListViewMode } from '../../types/ui'

import type {
  DashboardData,
  OrderDetail,
  OrderItem,
  OrderProductItem,
  OrderStatus,
  PendingOrderItem,
  SalesRepresentative,
} from '../../types/order'
import { InvoiceStatus } from '../../types/invoice'

export interface OrdersState {
  ordersList: OrderItem[]
  pendingOrdersList: PendingOrderItem[]
  currentOrder: OrderDetail | null
  ordersInProgress: number
  ordersInProgressTotal?: number
  ordersOnHold: number
  ordersOnHoldTotal?: number
  recentOrders?: number
  recentOrdersTotal?: number
  customerId?: string
  supportCode?: string
  currentOpenInvoices: number
  support: SalesRepresentative[]
  viewMode: ListViewMode
  showNotesModal: boolean
  notesModalMessage: string
  itemInfo: string
  orderLineItems?: Record<
    string,
    {
      isLoading: boolean
      lineItems: OrderProductItem[]
    }
  >
  config?: {
    sort?: 'new2old' | 'old2new'
    filters?: {
      status: OrderStatus[]
      invoiceStatus: InvoiceStatus[]
      invoiceDate: (string | number)[]
      orderDate: (string | number)[]
      shipDate: (string | number)[]
      query: string
    }
  }
}

const initialState: OrdersState = {
  ordersList: [],
  pendingOrdersList: [],
  currentOrder: null,
  currentOpenInvoices: 0,
  ordersInProgress: 0,
  ordersInProgressTotal: 0,
  ordersOnHold: 0,
  ordersOnHoldTotal: 0,
  recentOrders: 0,
  recentOrdersTotal: 0,
  supportCode: '',
  support: [],
  viewMode: 'table',
  showNotesModal: false,
  notesModalMessage: '',
  itemInfo: '',
}

export const ordersSlice = createSlice({
  name: 'ordersStore',
  initialState,
  reducers: {
    setOrders: (state, { payload }: PayloadAction<OrderItem[]>) => {
      state.ordersList = payload
    },
    setPendingOrders: (state, { payload }: PayloadAction<PendingOrderItem[]>) => {
      state.pendingOrdersList = payload
    },
    setOrder: (state, { payload }: PayloadAction<OrderDetail | null>) => {
      state.currentOrder = payload
    },
    setCustomerId: (state, { payload }: PayloadAction<string>) => {
      state.customerId = payload
    },
    setBusinessTracker: (
      state,
      {
        payload,
      }: PayloadAction<
        Pick<
          DashboardData,
          | 'ordersInProgress'
          | 'ordersOnHold'
          | 'currentOpenInvoices'
          | 'support'
          | 'ordersOnHoldTotal'
          | 'ordersInProgressTotal'
          | 'recentOrders'
          | 'recentOrdersTotal'
          | 'supportCode'
        >
      >
    ) => {
      state.currentOpenInvoices = payload.currentOpenInvoices
      state.ordersInProgress = payload.ordersInProgress
      state.ordersOnHold = payload.ordersOnHold
      state.support = payload.support
      state.ordersInProgressTotal = payload.ordersInProgressTotal
      state.ordersOnHoldTotal = payload.ordersOnHoldTotal
      state.recentOrders = payload.recentOrders
      state.recentOrdersTotal = payload.recentOrdersTotal
      state.supportCode = payload.supportCode
    },
    setSupport: (state, { payload }: PayloadAction<SalesRepresentative[]>) => {
      state.support = payload
    },
    setListViewMode: (state, { payload }: PayloadAction<ListViewMode>) => {
      state.viewMode = payload
    },
    setNotesModalInfo: (state, { payload }: PayloadAction<{ showNotesModal: boolean; notesModalMessage: string; itemInfo: string }>) => {
      state.showNotesModal = payload.showNotesModal
      state.notesModalMessage = payload.notesModalMessage
      state.itemInfo = payload.itemInfo
    },
    setSort: (state, { payload }: PayloadAction<'new2old' | 'old2new'>) => {
      if (state.config) {
        state.config.sort = payload
      } else {
        state.config = {
          sort: payload,
        }
      }
    },
    setFilter: (
      state,
      {
        payload,
      }: PayloadAction<{
        status: OrderStatus[]
        invoiceStatus: InvoiceStatus[]
        invoiceDate: (string | number)[]
        orderDate: (string | number)[]
        shipDate: (string | number)[]
        query: string
      }>
    ) => {
      if (state.config) {
        state.config.filters = payload
      } else {
        state.config = {
          filters: payload,
        }
      }
    },
    setOrderLineItems: (state, { payload }: PayloadAction<{ orderId: string; isLoading: boolean; orderLineItems: OrderProductItem[] }>) => {
      if (!state.orderLineItems) {
        state.orderLineItems = {
          [payload.orderId]: {
            isLoading: payload.isLoading,
            lineItems: payload.orderLineItems,
          },
        }
      } else {
        state.orderLineItems[payload.orderId] = {
          isLoading: payload.isLoading,
          lineItems: payload.orderLineItems,
        }
      }
    },
    clearOrderLineItems: (state) => {
      state.orderLineItems = undefined
    },
    resetDashboard: (state) => {
      state.ordersList = []
      state.pendingOrdersList = []
      state.currentOpenInvoices = 0
      state.ordersInProgress = 0
      state.ordersInProgressTotal = 0
      state.ordersOnHold = 0
      state.ordersOnHoldTotal = 0
      state.recentOrders = 0
      state.recentOrdersTotal = 0
      state.support = []
      state.config = {}
      state.customerId = undefined
      state.orderLineItems = undefined
    },
  },
})

export const {
  setOrders,
  setPendingOrders,
  setOrder,
  setBusinessTracker,
  setSupport,
  setListViewMode,
  resetDashboard,
  setNotesModalInfo,
  setSort,
  setFilter,
  setCustomerId,
  setOrderLineItems,
  clearOrderLineItems,
} = ordersSlice.actions
export const { reducer } = ordersSlice
