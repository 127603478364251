import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Invoice, InvoiceFilterOptions, InvoiceItem, InvoiceSortOption } from '../../types/invoice'

export interface InvoicesState {
  invoices: Invoice[]
  invoiceLineItems?: Record<string, { isLoading: boolean; lineItems: InvoiceItem[] }>
  config?: {
    orderNumber?: string
    sort?: InvoiceSortOption
    filters?: InvoiceFilterOptions
  }
}

const initialState: InvoicesState = {
  invoices: [],
  invoiceLineItems: {},
}

export const invoicesSlice = createSlice({
  name: 'invoicesStore',
  initialState,
  reducers: {
    setInvoices: (state, { payload }: PayloadAction<Invoice[]>) => {
      state.invoices = payload
    },
    setInvoiceLineItems: (state, { payload }: PayloadAction<{ invoiceId: string; lineItems: InvoiceItem[]; isLoading: boolean }>) => {
      if (!state.invoiceLineItems) {
        state.invoiceLineItems = {}
      }
      state.invoiceLineItems[payload.invoiceId] = {
        lineItems: payload.lineItems,
        isLoading: payload.isLoading,
      }
    },
    setSort: (state, { payload }: PayloadAction<InvoiceSortOption>) => {
      if (state.config) {
        state.config.sort = payload
      } else {
        state.config = {
          sort: payload,
        }
      }
    },
    setFilter: (state, { payload }: PayloadAction<InvoiceFilterOptions>) => {
      if (state.config) {
        state.config.filters = payload
      } else {
        state.config = {
          filters: payload,
        }
      }
    },
    setOrderNumber: (state, { payload }: PayloadAction<string>) => {
      if (state.config) {
        state.config.orderNumber = payload
      } else {
        state.config = {
          orderNumber: payload,
        }
      }
    },
    reset: (state) => {
      state.invoices = []
      state.invoiceLineItems = {}
      state.config = {}
    },
  },
})

export const { setInvoices, setInvoiceLineItems, setSort, setFilter, setOrderNumber, reset } = invoicesSlice.actions
export const { reducer } = invoicesSlice
